@import '../../../../../assets/css/variables';

.social-icon {
  display: flex;
  justify-content: flex-end;
  a {
    // color: $grey !important;
    margin: 0px 15px;
    display: inline-block;
    font-size: 30px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
