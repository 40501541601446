@import '../../../assets/css/variables';
@import '../../../assets/css/mixin';

.btn-wrapper {
  position: relative;
  .MuiButton-root.btn {
    // @include rounded(10px);
    // padding: 14px 20px;
    // text-transform: initial;
    // box-shadow: none;
    // @include Button-Text;
    // @media (max-width: 420px) {
    //   font-size: 11px;
    //   padding-left: 10px;
    //   padding-right: 10px;
    // }
    &.MuiButton-contained {
      // background: $medium-grey;
      // color: $dark-grey;
      &.MuiButton-containedPrimary {
        // background: $primary-color;
        // border-color: $primary-color;
        // color: $white;
      }
      &.Mui-disabled {
        // color: rgba(0, 0, 0, 0.26);
        // box-shadow: none;
        // background-color: rgba(0, 0, 0, 0.12);
      }
    }
    &.MuiButton-outlined {
      // padding: 13px 15px;
      &.MuiButton-outlinedPrimary {
        // color: $primary-color;
        // border-color: $primary-color;
      }
      &.MuiButton-outlinedSecondary {
        // color: $white;
        // border-color: $white;
        &.Mui-disabled {
          // border-color: rgba($white, 0.5);
          // color: rgba($white, 0.5);
        }
      }
    }
    &.MuiButton-textPrimary {
      // color: $primary-color;
    }
  }
  .MuiCircularProgress-root {
    // color: $success-color;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
}
