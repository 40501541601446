@import '../../../assets/css/variables.scss';

.offMarket__base{
	.offMarket__search-wrapper{
		position: relative;
		width: 100%;
	}
	.offMarket__search-container{
		box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
		position: absolute;
		left: 0;
		top: 0;
		margin-top: -30px;
		z-index: 100;
		// background: $white;
		width: 100%;
		overflow: scroll;
		max-height: 300px; //TODO adjust
		padding-top: 18px;
		.offMarket__row{
			// color: $grey;
			cursor: pointer;
			padding: 6px 16px;
			.offMarket__row-icon{
				font-size: 12px;
				margin-right: 16px
			}
			.offMarket__address{
				.typography__container{
					margin:0;
				}
				p{
					color: inherit;
					&.MuiTypography-subtitle1{
						font-weight: 700;
					}
				}
			}
		}
		.offMarket__row:hover{
			// background: $lighter-grey;
		}
	}
	.highlighted{
		font-weight:700;
		// color: $medium-black;
	}
	.offMarket__reset-btn{
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		padding: 4px;
    margin-top: 10px;
    margin-right: 8px;
	}
	.offMarket__input-field:hover + .offMarket__reset-btn,
	.offMarket__reset-btn:hover{
		display:block;
	}
}

.MuiCard-root.plan-filter{
	overflow: visible;
}

body[dir="rtl"]{
	.offMarket__base{
		.offMarket__search-wrapper .offMarket__input-field.MuiFormControl-root{
			&:before{
				left: auto;
				right: 0;
			}
			input{
				padding-right: 45px;
				padding-left: 14px;
			}
			label{
				margin-right: 32px;
				transition: .3s;
				&.Mui-focused{
					margin-right: 0;
				}
			}
		}
		.offMarket__reset-btn{
			right: auto;
			left: 0;
		}
	}
}
