@import '../../../assets/css/variables';
@import '../../../assets/css/mixin';

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    // background: red !important;
    // background: $white;
    // @include P3-Regular;
    // border: 1px solid $darker-grey;
    // @include box-shadow(3px, 6px, 21px, rgba(205, 205, 212, 0.296848));
    // @include rounded(20px)
    // padding: 16px;
    // color: #44444f;
    .MuiTooltip-arrow {
      // color: $white;
      &:after {
        width: 0;
        height: 0;
        margin: auto;
        content: '';
        display: block;
        border-style: solid;
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
    &.MuiTooltip-tooltipPlacementBottom {
      .MuiTooltip-arrow {
        &:before {
          position: relative;
          bottom: 0;
        }
        &:after {
          border-style: solid;
        //   // border-color: transparent transparent $darker-grey transparent;
          border-width: 0 1.25em 1.25em 1.25em;
          bottom: 0px;
          position: absolute;
          z-index: -1;
          left: -0.35em;
        }
      }
    }
    &.MuiTooltip-tooltipPlacementTop {
      .MuiTooltip-arrow {
        &:before {
          position: relative;
          bottom: 0;
        }
        &:after {
          border-style: solid;
          border-width: 1.25em 1.25em 0 1.25em;
        //   // border-color: $darker-grey transparent transparent transparent;
          bottom: -3px;
          position: absolute;
          z-index: -1;
          left: -0.35em;
        }
      }
    }
  }
}

.icon {
  cursor: pointer;
  @media all and (-ms-high-contrast: none){
    /* IE10+ CSS styles go here */
    background: none !important;
  }
}
.tooltip__container{
  display: flex;
  align-items: center;
}
