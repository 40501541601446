@import '../../../assets/css/mixin';
@import '../../../assets/css/variables';

.MuiDialog-root {
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.3);
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      // background: $white;
      // @include rounded(20px)
      @media (min-width: $medium) {
        margin: 32px;
        width: calc(100% - 64px);
      }
      &.MuiDialog-paperWidthXs {
        // max-width: 380px;
        .closeButton {
          top: 10px;
          right: 10px;
        }
      }
      &.MuiDialog-paperWidthSm {
        // max-width: 800px;
      }
      &.MuiDialog-paperWidthMd {
        // max-width: 900px;
      }
      .closeButton {
        position: absolute;
        top: 18px;
        right: 28px;
        z-index: 99;
        // color: $light-grey;
        width: 35px;
        height: 35px;
        padding: 5px;
      }
      .MuiDialogTitle-root {
        padding-top: 34px;
        padding-left: 40px;
        padding-right: 40px;
        .MuiTypography-root.MuiTypography-h3 {
          line-height: 31px;
        }
        .title-icon {
          text-align: center;
          margin: 10px auto 20px;
          // color: $success-color;
          font-size: 25px;
        }
      }
      .MuiDialogContent-root {
        padding: 0;
        .dialog-scroll-content {
          padding-left: 40px;
          padding-right: 40px;
          padding-bottom: 15px;
          p:only-child {
            margin-bottom: 0 !important;
          }
          .text-field-wrapper:only-child {
            margin-bottom: 0 !important;
          }
          .search-input {
            .text-field-wrapper:only-child {
              margin-bottom: 15px !important;
            }
          }
          .MuiDialogContentText-root {
            margin: 0;
          }
          .MuiTypography-root.MuiTypography-body2 {
            // color: $medium-black;
            margin-bottom: 15px;
          }
        }
      }
      .MuiDialogActions-root {
        justify-content: center;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        .btn-wrapper:only-child {
          width: 40%;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }

  &.confimation-dialog {
    .MuiDialog-container {
      .MuiDialog-paper {
        .MuiTypography-root.MuiTypography-h4 {
          // @include H3-Medium;
        }
        .MuiDialogActions-root {
          padding-bottom: 30px;
          .btn-wrapper {
            &:only-child {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.MuiDialog-root {
  &.full-page-popup-sm {
    .MuiDialog-container {
      .MuiDialog-paper {
        // background: $white;
        margin: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        border-radius: 0;
        padding-top: 12px;
        padding-bottom: 0;
        @media (min-width: $small) {
          &.MuiDialog-paperWidthXs {
            max-width: 80vw;
          }
        }
        @media (min-width: $medium) {
          padding: 24px 0 15px;
          margin: 32px;
          width: calc(100% - 64px);
          max-height: calc(100% - 64px);
          // @include rounded(20px)
          height: auto;
          &.MuiDialog-paperWidthXs {
            max-width: 400px;
          }
          &.MuiDialog-paperWidthSm {
            max-width: 800px;
          }
          &.MuiDialog-paperWidthMd {
            max-width: 900px;
          }
        }
        .closeButton {
          top: 10px;
          right: 10px;
        }
        .MuiDialogTitle-root,
        .MuiDialogActions-root {
          padding: 8px 20px 8px 20px;
          @media (min-width: $medium) {
            padding-left: 40px;
            padding-right: 40px;
          }
          /* UCX-1396 Add padding to bottom buttons on iOS */
          @media (max-width: $medium) {
            padding-bottom: 20px;
          }
        }

        .MuiDialogContent-root {
          padding: 0;
          .dialog-scroll-content {
            padding-left: 20px;
            padding-right: 20px;
            @media (min-width: $medium) {
              padding-left: 40px;
              padding-right: 40px;
            }
          }
        }
        .MuiDialogTitle-root {
          padding-right: 60px;
        }
      }
    }
  }
}

.card-confirmation-dialog {
  .MuiDialogActions-root {
    flex-direction: column;
    .btn-wrapper {
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0 !important;
      &:last-child {
        margin-bottom: 0;
      }
      .btn {
        width: 100%;
        // background: transparent;
        // color: $primary-color;
      }
    }
  }
}
// .full-page-popup-sm.terms-condition-dialog {
//   .MuiDialogContent-root {
//     h1 {
//       @include H0-Medium;
//       font-size: 2rem !important;
//       line-height: 2.5rem !important;
//       // color: #171725;
//       font-weight: bold;
//     }
//     h2 {
//       @include H2-Medium;
//       font-size: 1.875rem !important;
//       line-height: 2rem !important;
//       // color: #171725;
//     }
//     h3 {
//       @include H3-Medium;
//       // color: #171725;
//     }
//     h4 {
//       @include H4-Medium;
//       // color: #171725;
//     }
//     h5 {
//       @include H5-Medium;
//       // color: #171725;
//     }
//   }
// }
.full-page-popup-sm.plan-details-popup {
  .service-list {
    overflow: hidden;
    .corner-ribbon.top-left {
      top: 20px;
      left: -55px;
    }
    .basic-details .MuiTypography-root.detail-link {
      pointer-events: none;
    }
  }
  @media (max-width: $medium) {
    .MuiDialog-paper {
      padding-top: 0;
      .MuiDialogContent-root {
        padding: 0;
        .dialog-scroll-content {
          .service-list {
            margin-left: -20px !important;
            margin-right: -20px !important;
          }
        }
        .service-card {
          padding: 0px 20px;
          .img-rounded {
            margin: 0px -20px 20px;
            // @include rounded(0px);
          }
        }
      }
      .MuiDialogTitle-root {
        padding: 0;

        .corner-ribbon.top-left {
          top: 20px;
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none){
  /* IE10+ CSS styles go here */
  .page-scroll-inner{
    max-height: 100% !important;
  }  
}