@import '../../../assets/css/mixin';
@import '../../../assets/css/variables';
// @import '../../../assets/css/themes/theme';

.steps-container {
  padding-top: 70px;
  @media (min-width: $medium) {
    padding-top: 100px;
    padding-bottom: 20px;
  }
  .step-scroller {
    @media (min-width: $medium) {
      margin-right: 40px;
    }
    ul {
      @include reset-list;
      .steps-content {
        .steps-header {
          margin-top: 40px;
          margin-bottom: 30px;
          display: none;
          @media (min-width: $medium) {
            display: block;
          }
        }
      }
    }
  }
  & > .MuiContainer-root {
    padding: 0px;
    @media (min-width: $medium) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .content {
    flex-direction: column-reverse;
    position: relative;
    @media (min-width: $medium) {
      flex-direction: row;
      position: relative;
    }
  }
  .service-card {
    display: flex;
    width: 100%;
    min-height: 135px;
    margin-bottom: 40px;
    // background: $white;
    // border: 1px solid $grey;
    box-sizing: border-box;
    // border-radius: 20px;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    padding: 0;
    flex-direction: column;
    @media (min-width: $small) {
      flex-direction: row;

      &.has-image {
        flex-direction: column;
      }
    }
    &.card {
      box-shadow: none;
    }
    .corner-ribbon {
      line-height: 30px;
      &.top-left {
        top: 25px;
        left: -60px;
      }
    }
    .image-container {
      .img-rounded,
      .map-canvas {
        // background: $img-background;
        // border-radius: 20px 20px 0px 0px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-self: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        height: 150px;
        width: 100%;
        margin: 0;
        text-align: center;
        @media (min-width: $small) {
          height: 100%;
          width: 200px;
        }
        img {
          min-width: 100%;
          min-height: 100%;
          width: 100%;
        }
      }
      .map-canvas {
        // background: $lighter-grey;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .basic-details {
    
      margin: 10px 16px 30px;
      @media (min-width: $small) {
        margin: 30px;
      }
    }
    .loader-section {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .card.steps-wrapper {
    // box-shadow: none;
    // background: transparent;
    margin-top: 0;
    padding: 20px;
    pointer-events: none;
    cursor: default;
    display: none;
    @media (max-width: $medium) {
      margin-top: 60px;
      margin-bottom: 95px;
    }
    &.active {
      opacity: 1;
      pointer-events: initial;
      cursor: default;
      display: block;
    }
    &.stepHidden {
      display: none;
    }
    @media (min-width: $medium) {
      display: block;
      opacity: 0.5;
      &.active {
        opacity: 1;
        pointer-events: initial;
        cursor: default;
      }
    }
    @media (min-width: $medium) {
      padding: 30px;
      margin-bottom: 50px;
      // @include cardDesign;
    }
    .text-field-wrapper {
      .MuiFormControl-root {
        width: 100%;
      }
    }
    .desc p{
      // color: $dark-grey;
    }
    .custom-checkbox-row {
      flex-wrap: nowrap;
      &.MuiFormControlLabel-root {
        align-items: flex-start;
        width: 100%;
        .custom-checkbox.MuiCheckbox-root {
          padding-top: 0;
        }
        .MuiTypography-root {
          cursor: pointer;
        }
      }
    }
    .question-wrapper {
      margin-top: 15px;
      @media (min-width: $medium) {
        margin-top: 25px;
      }
      & > .typography__container  {
        margin-bottom: 15px;
        // color: $medium-black;
        @media (max-width: $medium) {
          .MuiTypography-body2{
            font-size: 13px;
          }
        }
      }
    }
    .radio-inline .MuiFormGroup-root {
      width: auto;
      .radio-group {
        width: auto;
        &.MuiFormControlLabel-root .MuiRadio-root ~ .MuiFormControlLabel-label {
          padding-right: 20px;
          padding-left: 20px;
          white-space: nowrap;
          min-height: 48px;
        }
      }
    }
  }
  .steps-footer {
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
    // background: $white;
    padding: 16px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 3px -6px 21px rgba(205, 205, 212, 0.25);
    & > .btn-wrapper {
      margin-left: 8px;
      margin-right: 8px;
      /* UCX-1396 Add padding to bottom buttons on iOS */
      @media (max-width: $medium) {
        padding-bottom: 10px;
      }
      &.push {
        margin-left: auto;
      }
    }
    @media (min-width: $medium) {
      background: transparent;
      padding: 0;
      position: static;
      box-shadow: none;
      padding-top: 30px;
    }
    @media (max-width: 420px) {
      padding: 10px 10px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      position: relative;
    }
    .btn-wrapper {
      width: 50%;
      @media (min-width: $medium) {
        width: auto;
      }
      .btn {
        width: 100%;
      }
    }
    &.MuiCardActions-root {
      justify-content: flex-end;
    }
    &.full-width {
      @media (min-width: $medium) {
        flex-direction: column;
      }
      .btn-wrapper {
        @media (min-width: $medium) {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .stepper {
    @include reset-list;
    padding-left: 60px;
    background: #FFF;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 70px;
    left: 0;
    @media (min-width: $medium) {
      background: transparent;
      padding-bottom: 0px;
      padding-top: 10px;
      overflow-y: auto;
      left: auto;
      position: fixed;
      width: 320px;
      top: 140px;
      &.sticky {
        position: fixed;
        margin: 0;
      }

      &.abs {
        position: absolute;
        bottom: 0px;
        width: 320px;
        top: inherit;
      }
    }
    & > li {
      position: relative;
      // display: none;
      // @media (min-width: $medium) {
      //   display: block;
      // }
      .typography__container{
        @media (min-width: $medium) {
          margin-bottom: 10px;
        }
        .MuiTypography-root.MuiTypography-h4 {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 0px;
          @media (min-width: $medium) {
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
      .count {
        // position: absolute;
        // width: 32px;
        // height: 32px;
        // // background: $dark-grey;
        // // color: $white;
        // border-radius: 50%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // left: -45px;
        // // font-family: $font-family-1;
        // font-style: normal;
        // font-weight: 500;
        // font-size: 14px;
        // line-height: 21px;
        // z-index: 9;
        // @media (min-width: $medium) {
        //   left: -60px;
        // }
      }
      &.active {
        display: block;
      }
      &.active,
      &.completed {
        .count {
          // background: $primary-color;
        }
      }
      &.completed {
        &:after {
          content: '';
          height: 100%;
          width: 2px;
          // background: $primary-color;
          position: absolute;
          top: 16px;
          left: -45px;
          z-index: 1;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
      &.no-count {
        margin-left: -40px;
      }
    }
    .sub-step {
      @include reset-list;
      margin-bottom: 15px;
      display: none;
      @media (min-width: $medium) {
        display: block;
      }
      li {
        position: relative;
        .service-card {
          margin-bottom: 10px;
          .loader-section {
            height: 130px;
          }
          .corner-ribbon {
            display: none;
          }
          .basic-details {
            margin: 15px;
            .MuiTypography-root {
              &.MuiTypography-h3 {
                // font-size: 18px;
                // line-height: 27px;
                &.heading3-icon {
                  margin-bottom: 5px !important;
                  align-items: flex-start;
                  .icon {
                    font-size: 22px;
                    margin-right: 5px;
                    margin-top: 4px;
                  }
                }
              }
              &.MuiTypography-h4 {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 0;
              }
              &.gst {
                margin-bottom: 8px;
              }
            }
          }
        }
        .sub-step-item {
          position: relative;
          // @include P3-Regular;
          // color: $dark-grey;
          margin: 4px 0px;
          display: block;
          cursor: pointer;
          &.inactive {
            // color: $dark-black;
            cursor: not-allowed;
          }
        }
        &.completed {
          .sub-step-item {
            &:before {
              content: ' ';
              position: absolute;
              left: -22px;
              top: 3px;

              content: ' ';
              background-repeat: no-repeat;
              width: 15px;
              height: 15px; 
              display:block;
              background-image: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path style="fill: green" fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>');
              // content: '\f105';
              // font-family: $font-awesome;
            }
          }
        }
        &.active {
          .sub-step-item {
            // color: $primary-color;
          }
        }
      }
    }
    .bottom-borders {
      padding: 0;
      @include reset-list;
      display: flex;
      margin: 0px -1px;
      position: absolute;
      width: 100%;
      height: 5px;
      bottom: -5px;
      left: 0;
      // background: $white;
      & > li {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        margin: 0px 1px;
      }
      .sub-step {
        width: 100%;
        height: 5px;
        margin: 0;
        @include reset-list;
        display: flex;
        li {
          height: 5px;
          // background: $step-border;
          padding: 0;
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          &.active,
          &.completed {
            // background: $primary-color;
          }
        }
      }
    }
  }
  .sidebar-right .stepper > li .typography__container {
    @media (min-width: $medium) {
      margin-bottom: 3px;
    }
  }
}


@media (min-width: $medium) {
  .parent-elem {
    position: relative;
    height: 150px;
    overflow: hidden;
    .child-elem {
      position: absolute;
      width: 100%;
    }
    &.top {
      .child-elem {
        bottom: 0;
      }
    }
    &.bottom {
      height: 200px;
      .child-elem {
        top: 0;
      }
    }
  }
}

// body[dir="rtl"]{
//   .steps-container {
//     .step-scroller {
//       @media (min-width: $medium) {
//         margin-left: 40px;
//         margin-right: 0;
//       }
//     }
//     .stepper {
//       padding-right: 60px;
//       padding-left: 0;
//       & > li .count{
//         right: -60px;
//         left: auto;
//       }
//       li.completed {
//         .sub-step-item {
//           &:before {
//             right: -22px;
//             left: auto;
//           }
//         }
//         &:after {
//           right: -45px;
//           left: auto
//         }
//       }
//     }
//   }
//   .card .MuiCard-root.payment-card .detail-link{
//     right: auto;
//     left: 26px;
//   }
// }

