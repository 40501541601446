@import '../../../assets/css/mixin';
@import '../../../assets/css/variables';
// @import '../../../assets/css/themes/theme';

.radio-inline {
  margin: 0px -5px 0;
  padding-bottom: 15px;

  .MuiFormGroup-root {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;

    .radio-group {
      position: relative;
      min-height: 1px;
      flex-grow: 1;
      max-width: 100%;
      margin: 0 5px 5px;

      &.MuiFormControlLabel-root {
        .MuiButtonBase-root {
          position: absolute;
          width: 100%;
          text-align: center;
          top: 0;
          height: 100%;
          border-radius: 0;
          align-items: flex-start;
          // @include rounded(10px);
        }

        .MuiRadio-root {
          &~.MuiFormControlLabel-label {
            // background: $medium-grey;
            // @include rounded(10px);
            padding: 5px 5px;
            // border: 1px solid $medium-grey;
            // @include P4-Regular;
            font-weight: 600;
            // color: $dark-grey;
            height: 100%;
            line-height: 1.4;
            width: 100%;
            display: flex;
            align-items: center;
            // padding-top: 35px;
          }

          &.Mui-checked~.MuiFormControlLabel-label {
            // background: rgba(0, 98, 255, 0.1);
            // border-color: $primary-color;
            // color: $primary-color;
          }
        }

        .radio-item {
          width: 100%;
          text-align: center;

          p {
            margin: 0;
            line-height: 1.45;

            &.text2 {
              font-size: 14px;
            }

            &.text3 {
              font-size: 14px;
              margin-bottom: 5px;
              font-weight: 500;
            }
          }
        }
      }

      &.icon-hidden {
        &.MuiFormControlLabel-root .MuiButtonBase-root {
          .MuiIconButton-label {
            .MuiSvgIcon-root {
              display: none;
            }
          }
        }

        .MuiTypography-root {
          padding:0;
        }
      }

      .MuiRadio-colorSecondary.Mui-checked {
        // color: $primary-color;
      }
    }

    &.two-line {
      .MuiFormControlLabel-root.radio-group {
        max-width: 230px;

        .MuiRadio-root~.MuiFormControlLabel-label {
          white-space: normal !important;
        }
      }
    }
  }
}