@import '../../../../../assets/css/variables.scss';
@import '../../../../../assets/css/mixin.scss';



@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}


.plan-wrapper {
  position: relative;
  min-height: 300px;
  overflow-x: hidden;
  padding-top: 20px;
  @media (min-width: $medium) {
    padding-top: 50px;
  }
  &.has-selected-offers {
    padding-bottom: 70px;
  }

  .plans {
    padding: 20px 0px 80px;
    &.odd-plan {
      // background: transparent;
    }
    &.even-plan {
      // background: $white;
    }
    .blank-page {
      height: 290px;
    }
    .table-wrapper {
      margin-top: 40px;
      @media (min-width: $medium) {
        margin-bottom: 40px;
      }
    }
  }

  .services {
    @media screen and (min-width: $small) {

      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @media screen and (min-width: $medium) {
      max-width: 1280px;
      margin: 0 auto;
    }
      


    .heading3-icon {
      margin-bottom: 30px;
    }

    .service-list-carousel {
      margin-top: 25px;
      position: relative;
      padding-left: 20px;
      @media (min-width: $small) {
        margin-right: -20px;
        padding: 0;
      }
      @media (min-width: $medium) {
        margin-right: 0;
      }
      .blank-page {
        padding-left: 0px;
        @media (min-width: $medium) {
          padding-left: 0;
          padding-right: 0;
          height: 800px;
          align-items: flex-start;
          padding-top: 220px;
        }
      }
      .slick-slider {
        .slick-list {
          // overflow: hidden;
          .slick-track {
            display: flex;
            .slick-slide {
              padding-right: 20px;
              display: flex;
              height: auto;
              align-items: center; //optional
              justify-content: center; //optional
              & > div {
                height: 100%;
                width: 100%;
              }
            }
          }
        }

        .slick-arrow {
          display: none !important;
          &.slick-prev,
          &.slick-next {
            width: 78px;
            height: 78px;
            border-radius: 50%;
            // background: $white;
            // border: 1px solid $primary-color;
            z-index: 99;
            &.slick-disabled {
              display: none !important;
              opacity: 0;
            }
          }
          &.slick-next {
            right: 30px;
            &:before {
              content: ' ';
              background-repeat: no-repeat;
              width: 40px;height: 20px; display:block;
              margin-left:17px;
              background-image: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path style="fill: white;" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>');
              // content: '\f105';
              // font-family: $font-awesome;
              font-style: normal;
              font-weight: 300;
              text-decoration: inherit;
              // color: $primary-color;
              font-size: 35px;
            }
          }
          &.slick-prev {
            left: 30px;
            &:before {
              content: ' ';
              background-repeat: no-repeat;
              width: 40px;height: 20px; display:block;
              margin-left:14px;
              background-image: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path style="fill: white;" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>');
              // content: '\f104';
              // font-family: $font-awesome;
              font-style: normal;
              font-weight: 300;
              text-decoration: inherit;
              // color: $primary-color;
              font-size: 35px;
            }
          }
        }
        &:hover {
          .slick-arrow {
            display: block !important;
          }
        }
      }
    }
  }
}
.service-list {
  @include reset-list;
  margin: 0 !important;
  border-right: none;
  position: relative;
  &.no-promocodes .service-list-item:not(.has-promocode){
    margin-top: 0;
    height: 100%;
  }
  .service-list-item {

    // background: $white;
    // border: 1px solid $darker-grey;
    // padding: 20px;
    position: relative;
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    // max-width: 75vw;
    // @media (min-width: $small) {
    //   // padding: 30px;
    //   max-width: 33vw;
    // }
    // @media (min-width: $medium) {
    //   // padding: 30px;
    //   max-width: 25vw;
    // }
    // @media (min-width: $large) {
    //   // padding: 30px;
    //   max-width: 20vw;
    // }
    & + .service-list-item {
      border-left: none;
    }

    $promo-message-height: 110px;
    &.has-promocode .promo-message {
      height: $promo-message-height;
    }
    &:not(.has-promocode) {
      @media (min-width: $small) {
        margin-top: $promo-message-height;
        // This height change is here to avoid changing the overflow on the slideshow, which would result in having a vertical scrollbar
        min-height: calc(100% - #{$promo-message-height});
        height: calc(100% - #{$promo-message-height});
      }
    }

    .service-card {
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      // max-width: 250px;
      // // padding-bottom: 140px;
      // @media (min-width: $small) {
      //   max-width: 280px;
      // }
      .basic-details .MuiTypography-root.detail-link {
        pointer-events: none;
      }
      // .feature-list {
      //   max-height: 170px;
      //   overflow: auto;
      // }

      .footer-btn {
        margin-top: 20px;
        // position: absolute;
        // bottom: 0;
        // width: 100%;
        .btn-wrapper.select__button .btn{
          padding-top: 20px !important;
          padding-bottom: 20px !important;
          font-size: 18px;
          font-weight: 800;

          
          .MuiButton-label  {
            animation-iteration-count: 1;
            animation-timing-function: ease-in-out;
            animation-delay: 5s;
            animation-name: headShake;
            animation-duration: 1s;
            animation-fill-mode: both;
            
          }
        }
      }
    }
    &.selected {
      // background: rgba(0, 98, 255, 0.1);
      // border: 1px solid $primary-color;
    }
  }
}

.search-identifier-dialog {
  .search-input {
    @media (min-width: $small) {
      margin-right: 20px;
    }
    .MuiFormHelperText-root.MuiFormHelperText-contained {
      @media (min-width: $small) {
        position: absolute;
        bottom: -20px;
      }
    }
  }
}

.services__one-item .slick-center{
  @media (max-width: $small){
    margin: 0 auto;
  }
}

.not-available{
  // color: $error-color;
}

body[dir="rtl"]{
  .plan-wrapper{
    text-align: right;
  }
}

.slick-slider .image-container img {
  display: inline-block;
}

