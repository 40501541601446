@import "../../../../../assets/css/variables";
@import "../../../../../assets/css/mixin";

.plan-filter {
  
  &.MuiCard-root {
    &.card {
      padding-left: 0;
      padding-right: 0;
    }
    border-radius: 0;
    padding: 30px 0px;
    box-shadow: 3px 6px 21px rgba(205, 205, 212, 0.296848);
    .text-field-wrapper {
      margin-bottom: 0;
    }
    .typography__container {
      margin-bottom: 15px;
    }
    .btn-wrapper {
      // display: inline-block;
      // width: 100%;
      // @media (min-width: $medium) {
      //   min-width: 150px;
      //   width: auto;
      // }
      // .btn {
      //   width: 100%;
      // }
    }
    .side-filter-panel {
      height: 100%;
      @media (min-width: $large) {
        // border-left: 1px solid $medium-grey;
        padding: 0 0 0 30px;
        margin-left: 30px;
      }
      .solar-panel {
        .MuiTypography-root.MuiTypography-h4 {
          margin-bottom: 0;
        }
        .solar-checkbox {
          display: flex;
          align-items: center;
          height: 46px;
          
        }
      }
    }
  }

  .custom-checkbox {
    padding-top: 8px;
    & ~ .MuiTypography-root {
      &.MuiFormControlLabel-label {
        font-size: 16px;
        &.MuiTypography-body1 {
          // color: $text1-color;
        }
      }
    }
  }
  
  .d2d-checkbox-wrapper {
    margin-top: -25px;
    margin-bottom: 35px;
  }
}
.promocode-popup {
  .MuiTypography-h4 {
    display: block;
    margin-bottom: 20px;
  }
  .promocode .typography__container {
    margin-bottom: 0.5rem !important;
  }
}
.promocode {
  position: relative;
  // margin-bottom: 15px;
  .btn-wrapper {
    width: auto;
    display: inline-block;
    margin-top: 29px;
    margin-left: 0px;
    margin-bottom: 10px;
    padding-left: 20px;
    @media (min-width: $small) {
      margin-bottom: 0px;
    }
    .btn {
      min-width: 100px;
      width: auto;
      @media (min-width: $small) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .text-field-wrapper {
    margin-bottom: 15px !important;
  }
  .MuiInputAdornment-root {
    // color: $success-color;
    .close {
      // color: $light-grey;
      cursor: pointer;
    }
  }
  .promocode-link {
    text-decoration: underline;
  }
  .promocode-error {
    // color: $error-color;
    font-weight: 500;
  }
}

// body[dir="rtl"]{
//   .plan-filter.card {
//     .side-filter-panel{
//       @media (min-width: $large) {
//         padding-right: 30px;
//         margin-right: 30px;
//         margin-left: 0;
//         padding-left: 0;
//         border-left: 0;
//         // border-right: 1px solid $medium-grey;
//       }
//     }
//     .checkbox-group.MuiFormControlLabel-root
//       .MuiCheckbox-root
//       ~ .MuiFormControlLabel-label {
//       padding-right: 40px;
//       padding-left: 14px;
//     }
//   }
//   .promocode{
//     .btn-wrapper{
//       padding-right: 20px;
//       padding-left: 0;
//     }
//   }
// }

.solar-checkbox { 
    border: 1px solid #e2e2ea;
    height: 46px;
    // border-radius: 3px;
    display: flex !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }