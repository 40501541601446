@import '../../../assets/css/variables';
@import '../../../assets/css/mixin';
// @import '../../../assets/css/themes/theme';

.display-block-important {
  display: block !important;
}

.MuiAppBar-root.selected-plan-button-wrapper {
  position: fixed;
  top: auto;
  bottom: 0;
  right: 0;
  z-index: 999;
  height: auto;

  // background: $primary-color;
  // @media (min-width: $medium) {
  //   width: auto;
  // }

  ul {
    @include reset-list;
    // font-family: $font-family-1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    // justify-content: center;

    justify-content: space-between;

    li {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      padding-top: 0;
      padding-bottom: 0;
      color: #fff;
      text-align: center;

      .product-scroll {
        // height: 60px;
        padding: 0;

        @media (min-width: $medium) {
          width: 360px;
          margin: 0 40px;
          // height: 70px;
        }

        &.single-data {
          @media (min-width: $medium) {
            width: 180px;
          }
        }

        ul {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          // padding: 10px 0px 9px;

          @media (min-width: $medium) {
            // padding: 10px 0px;
          }

          li {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;

            @media (min-width: $medium) {
              border-right: 2px solid rgba(255, 255, 255, 0.4);
            }

            &.links {
              letter-spacing: 1px;
              position: relative;
              opacity: 0.5;
              padding: 0 20px 0 0;
              @media (max-width: 360px) {
                padding: 0 15px;
              }
              @media (min-width: $medium) {
                padding: 0 40px;
              }
              &.selected, &:hover {
                opacity: 1;
              }
              a {
                // color: $lighter-grey;
                display: block;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;

                .icon {
                  display: block;
                  font-size: 16px;
                  margin-right: 8px;
                  margin-top: 2px;
                  @media (min-width: $medium) {
                    margin-right: 8px;
                    margin-top: 1px;
                  }
                }

                .service-text {
                  // display: none;
                }
              }

              &:last-child {
                border-right: 0;
              }
            }
          }
        }
      }

      @media (min-width: $small) {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
      }

      @media (min-width: $medium) {
        // padding: 1px 40px;
        // @include H5-Medium;
        width: auto;
        text-align: left;
      }

      &.label {
        // display: none;
        -ms-flex-preferred-size: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 120px;
        max-width: 120px;

        display: inline-flex;
        @media (min-width: $medium) {
          padding-right: 0;
          color: #fff;
        }
      }

      &.next-button {
        text-align: center;
        // width: 200px;

        // @media (max-width: $medium) {
        //   margin: 0 1.25rem;
        // }

        @media (min-width: $medium) {
          width: auto;
        }

        .btn-wrapper {
          .btn {
            white-space: nowrap;

            &.Mui-disabled {
              cursor: not-allowed;
              pointer-events: auto;
            }
          }
        }
      }
    }
  }

  ul.selected-plan-button-wrapper__inner {
    padding: 15px 0;
  }
}

body[dir='rtl'] {
  .MuiAppBar-root.selected-plan-button-wrapper {
    right: auto;
    left: 0;

    ul li.label {
      padding-left: 0;
      padding-right: 40px;
    }
  }
}

.plan-wrapper .next-button svg.MuiCircularProgress-svg {
  // color: $white-color;
}
