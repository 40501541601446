@import '../../../../../assets/css/variables.scss';

.basic-details {
  // width: 100%;
  // margin-bottom: 20px;
  .basic-details__summary{
    div.typography__container {
      // margin-bottom: 8px;
    }
  }
  .gst,
  .desc{
    // color: $medium-black;
    // margin-bottom: 15px;
  }
  .desc p {
    // font-size: 12px;
  }
  .MuiTypography-root {
    &.detail-link {
      text-decoration: underline;
      font-size: 14px;
    }
  }
  .icon-info {
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    // color: $text2-color;
    font-size: 26px;
    line-height: 26px;
  }
  div.price-details *{
    // color: $primary-color;
  }


  .reference_price,
  .reference_price_without_discounts,
  .distribution_area,
  .retail_tariff,
  .annual_usage,
  .units,
  .market_segment,
  .price_difference {
    font-weight: 700 !important;
  }


}

// Style for plan vertical card
.service-list {
  .basic-details {
    .gst,
    .desc{
      // color: $medium-black;
      // margin-bottom: 40px !important;
    }
    .desc p {
      // font-size: 12px;
    }
  }
}

// Style for plan horizontal card
.steps-content {
  .basic-details__summary {
    h2{
      font-size: 28px;
      margin-bottom: 0;
    }
  }
}
.sub-step .service-card-list{
  .basic-details__summary {
    .typography__container {
      margin-bottom: 0;
    }
    h2, .icon{
      font-size: 24px;
      margin-bottom: 0;
      line-height: 22px !important;
    }

    h5 {
      margin-top:0;
    }
  }
  .basic-details__summary, .basic-details{
    text-align: left;
    flex-grow: 1;
  }
}

.service-list.popup-content {
  max-width: 280px;
  margin: 20px auto 0px !important;
  @media (min-width: $small) {
    max-width: 280px;
    width: 90%;
    margin: 30px auto 0px !important;
  }
  .service-card {
    overflow: hidden;
    .corner-ribbon {
      top: 25px;
      left: -60px;
      line-height: 30px;
    }
    .img-rounded {
      background-size: auto;
    }
    .basic-details .MuiTypography-root.detail-link {
      pointer-events: none;
    }
  }
}

.MuiDialog-root.plan-details-popup {
  &.full-page-popup-sm {
    .MuiDialog-container {
      @media (max-width: $medium) {
        .MuiDialog-paper {
          padding-top: 0;
          .MuiDialogTitle-root {
            padding: 0;
          }
        }
      }
    }
  }
}

body[dir="rtl"]{
  .basic-details .icon-info{
    left: 20px;
    right: auto;
  }
}



.basic-details__summary-heading {
  line-height: 1.1 !important;
}



s,
strike {
  text-decoration: none;
  /*we're replacing the default line-through*/
  position: relative;
  display: inline-block;
  /* keeps it from wrapping across multiple lines */
}

s:after,
strike:after {
  content: "";
  /* required property */
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 4px solid rgba(255,0,0,0.8);
  height: 60%;
  /* adjust as necessary, depending on line thickness */
  /* or use calc() if you don't need to support IE8: */
  height: calc(60% - 1px);
  /* 1px = half the line thickness */
  width: 100%;
  transform: rotateZ(-10deg);
}