

.searchLocation-wrapper {
	.typography__container {
		margin-bottom: 0.5rem !important;
	}

	.icon {
		line-height: 1 !important;
		font-size: 1.25rem !important;
	}
}

// bit of a hack
.MuiAutocomplete-root.search-input .MuiOutlinedInput-notchedOutline {
	font-size: 1.125rem;
}