.steps-header {
  opacity: 0.5;
  &.active {
    opacity: 1;
  }
  &.hidden {
    visibility: hidden;
  }

  .typography__container + .typography__container {
    margin-top: 10px;
  }
}
