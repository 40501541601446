@import "../../../assets/css/variables";
@import "../../../assets/css/mixin";

.MuiCard-root {
  &.feature-card {
    // @include cardDesign;
    height: 100%;
    @media (min-width: $medium) {
      margin: 0 20px;
    }
    .card-image {
      height: 180px;
    }
    .MuiCardContent-root {
      padding-top: 50px;
      padding-bottom: 40px;
    }
    .MuiTypography-body2 {
      // @include P1-Regular;
      // font-family: $font-family-2;
      margin: 0;
    }
  }
}
