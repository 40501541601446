@import "../../../assets/css/variables";
@import "../../../assets/css/mixin";

.alert-wrapper {
  &.MuiAlert-root {
    // @include rounded(20px)
    // margin: 20px auto;
    .MuiTypography-root {
      &.MuiTypography-body2 {
        //@TODO fixme
        // color: inherit;
        // font-size: 15px;
        // margin: 5px 0px;
      }
    }
    .MuiAlert-icon {
      // align-items: center;
    }
    .MuiAlert-action {
      padding-top: 8px;
      align-items: flex-start;
    }
    &.MuiAlert-standardInfo {
      // background: $ligher-secondary-color;
    }
    &.MuiAlert-filledError {
      // background: $error-color;
    }
    &.MuiAlert-filledSuccess {
      // background: $success-color;
    }
    &.MuiAlert-filledWarning{
      // background: $progress-color;
    }
  }
}
