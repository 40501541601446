@import '../../../assets/css/mixin';
@import '../../../assets/css/variables';
// @import '../../../assets/css/themes/theme';


.text-field-wrapper {
  margin-bottom: 15px;
  .MuiFormControl-root {
    // font-family: $font-family-2;
    // width: 100%;
    .MuiFormLabel-root {
      // font-family: $font-family-2;
      // font-size: 15px;
      &.Mui-focused {
        // color: $primary-color;
      }
      &.Mui-error {
        // color: $error-color;
      }
    }
    .MuiOutlinedInput-root {
      // @include rounded(10px);
      // font-family: $font-family-2;
      .MuiInputBase-input {
        // font-size: 15px;
      }
      .MuiOutlinedInput-input {
        // padding-top: 14.5px;
        // padding-bottom: 14.5px;
      }
      .MuiOutlinedInput-notchedOutline {
        // border-color: $darker-grey;
      }
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          // border-color: $primary-color;
          // border-width: 1px;
        }
      }
      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          // border-color: $error-color;
        }
      }
      .MuiInputAdornment-root {
        &.MuiInputAdornment-positionEnd {
          // cursor: pointer;
        }
      }
    }

    .MuiInputLabel-formControl:not(.MuiInputLabel-shrink) {
      top: -3px;
      
    }

    

  }
}

.MuiAutocomplete-root.search-input .text-field-wrapper .MuiFormControl-root .MuiInputLabel-formControl:not(.MuiInputLabel-shrink){
      top: 2px;
    }

   .MuiAutocomplete-root label{
      // font-size: 18px !important;
    }

.MuiFormHelperText-root.MuiFormHelperText-contained {
  // line-height: 16px;
  // letter-spacing: 0.1px;
  // color: $dark-grey;
  // font-size: 13px;
  // margin-top: 2px;
  // flex-basis: 100%;
  // margin-left: 14px;
  // margin-right: 14px;
  &.Mui-error {
    // color: $error-color;
  }
}

.MuiAutocomplete-root {
  .MuiFormControl-root {
    position: relative;
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      // padding-top: 0;
      // padding-bottom: 0;
    }
  }
  // &.location-selectbox {
  //   @extend .search-input;
  // }
}
.MuiAutocomplete-listbox {
  .MuiAutocomplete-option {
    &:active,
    &[data-focus='true'] {
      // background-color: $lighter-grey;
    }
    // .icon-marker {
    //   color: $grey;
    //   margin-right: 1rem;
    //   font-size: 1.75rem;
    // }
  }
}
// .search-result {
//   .MuiTypography-root {
//     &.MuiTypography-body2 {
//       color: $medium-black;
//     }
//     &.MuiTypography-subtitle1 {
//       color: $dark-grey;
//     }
//   }
// }

// .search-input {
//   .MuiFormControl-root {
//     &:before {
//       content: '\f002';
//       position: absolute;
//       left: 0;
//       font-family: $font-awesome;
//       font-weight: normal;
//       text-rendering: auto;
//       color: $light-grey;
//       font-size: 16px;
//       width: 48px;
//       height: 48px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//     .MuiInputLabel-formControl {
//       left: 28px;
//       &.MuiInputLabel-shrink {
//         left: 0;
//       }
//     }
//     .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
//     .MuiOutlinedInput-input {
//       padding-left: 45px;
//     }
//   }
// }
