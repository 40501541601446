@import '../../../../assets/css/variables.scss';

.success-wrapper {
  width: 100%;
  max-width: 550px;
  margin: 120px auto;

  .MuiTypography-root{
    text-align: center;
  }
  .success-card {
    padding: 0px 20px;
    @media (min-width: $medium) {
      padding: 0;
    }
    img {
      margin: 0px auto 20px;
      display: block;
    }
    .card {
      box-shadow: 3px 6px 21px rgba(205, 205, 212, 0.296848);
      padding: 20px;
      // color: $medium-black;
      @media (min-width: $medium) {
        padding: 50px;
      }
      .MuiTypography-caption {
        // color: $medium-black;
        margin: 10px 0px 20px;
      }
      .MuiTableRow-root .MuiTableCell-root:first-child {
        // color: $dark-grey;
      }
    }
    .img-alignment {
      text-align: center;
      width: 100%;
      img {
        display: block;
        margin: 0px auto;
      }
    }
    .btn {
      margin: 0px auto;
    }
  }
  .failure-card {
    padding: 0px 30px;
    .heading__container.typography__container{
      margin: 0px auto 30px;
    }
    .btn {
      width: 100%;
      max-width: 300px;
      margin: 0px auto;
    }
  }
}

.text__note{
  // color: $dark-grey;
  font-size: 0.85rem;
}