@import '../../../assets/css/variables.scss';

.footer {
  // background: $white;
  padding: 40px 0 30px;
  // @include box-shadow(inset 0 1px 0px $darker-grey);
  .MuiTypography-root {
    &.copyright {
      // font-family: $font-family-2;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.1px;
      // color: $light-grey;
      text-align: left;
      @media (min-width: $medium) {
        text-align: right;
      }
    }
  }
  .MuiDivider-root {
    margin: 30px 0px;
  }
}
