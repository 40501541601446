@import '../../../../assets/css/variables.scss';

table.detail-table {
  width: 100%;
  margin-bottom: 40px;
  table-layout: fixed;

  tr {
    td {

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.4;


      // color: $medium-black;
      vertical-align: top;
      width: 60%;

      @media (min-width: $medium) {
        width: 70%;
      }

      &.bold {
        font-weight: bold;
      }

      &:first-child {
        width: 40%;

        @media (min-width: $medium) {
          width: 30%;
        }
      }

      &:last-child {
        word-break: break-all;
      }
    }
  }
}

.resend-email {
  cursor: pointer;
  // color: $primary-color;
  margin-left: 4px;
}

body[dir="rtl"] {
  .custom-checkbox-row .custom-checkbox.MuiCheckbox-root.custom {
    margin-left: 10px;
    margin-right: 0;
  }
}

#marketing-tip {
  font-size: 11px;
}