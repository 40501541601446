@import '../../../assets/css/variables.scss';

.navbar {
   margin: 0;
  padding: 0;
  list-style: none;
  li {
     display: inline-block;
    margin-left: -2px;
    
    display: inline-block;
    a {
      letter-spacing: 0.1px;
      // color: $text-color;
      text-decoration: none;
      // @include P3-Regular;
      // font-family: $font-family-1;
      &:hover {
        // color: $primary-color;
      }
      &.activeItem {
        // color: $primary-color;
      }
    }
    &:hover {
      background: transparent;
    }
    &.MuiMenuItem-gutters {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  &.footer-link {
    padding: 0;
    li {
      padding: 5px 0;
      display: block;
      @media (min-width: $medium) {
        padding: 0 20px;
        display: inline-block;
      }
      @media (min-width: $large) {
        padding: 0 40px;
      }
      a {
        // font-family: $font-family-2;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.1px;
        // color: $light-grey;
        &:hover {
          // color: $primary-color;
        }
      }
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
