@import '../../../../../assets/css/variables.scss';
@import '../../../../../assets/css/mixin.scss';

.service-card {
  position: relative;
  height: 100%;
  width: 100%;
  .feature-list {
    @include reset-list;
    padding: 10px 0;
    li {
      padding: 3px 0px;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        cursor: auto;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .check-icon {
      margin-top: 3px;
      margin-right: 8px;
      // color: $primary-color;
    }
  }

  .greenpower-options.text-field-wrapper {
    width: 85%;
    float: left;

    .MuiFormControl-root,
    .MuiOutlinedInput-root {
      width: 100%;
    }

    .MuiSelect-select {
      font-size: 14px;
      padding-left: 40px;
      background-image: url('../../../../../assets/images/greenpower-icon.png');
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 15px 10px;
    }

    &.placeholder-item .MuiSelect-select {
      padding-left: 14px;
      background: none;
    }
  }
}

.greenpower-menu-item {
  li {
    padding-left: 40px;
    background-image: url('../../../../../assets/images/greenpower-icon.png');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 15px 5px;
    &:first-child {
      display: none;
    }
  }
}

.MuiTableContainer-root.greenpower-dialog-table {
  width: 95%;
  margin: 0 auto;

  .MuiTable-root {
    .MuiTableCell-head {
      text-transform: none;
      font-weight: bold;
      font-size: 14px;
    }

    .MuiTableBody-root td {
      font-size: 14px;
      &:first-child {
        font-weight: bold;
      }
    }
  }
}

.rates__card .rates {
  margin-bottom: 20px;
  .text1 {
    // color: $light-grey;
  }
  .text2 {
    // color: $text1-color;
  }
  .text3 {
    font-weight: bold;
    // color: $success-color;
  }
}

.rates__section
  .accordian-panel.MuiPaper-elevation1.MuiAccordion-root
  .MuiAccordionSummary-root {
  justify-content: center;
  .MuiAccordionSummary-content {
    display: block;
    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.img-rounded {
  // background: $img-background;
  // @include rounded(20px)
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 20px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
  }
}

/* The ribbons */

.corner-ribbon {
  width: 200px;
  // background: $primary-color;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  letter-spacing: 1px;
  // color: $white;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  // font-family: $font-family-1;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  text-transform: uppercase;
  white-space: nowrap;
  &.sticky {
    position: absolute;
  }
  &.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }
  &.top-left {
    top: 0px;
    left: -75px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

.rates {
  margin: 0 -2rem;
}
.rates__card {
  width: 100%;
}
.rates__container {
  display: flex;
  justify-content: space-between;
  &.header {
    margin-top: 1rem;
  }
}

.rate__item-container {
  display: flex;
  
  justify-content: space-between;
  &.header {
    font-weight: 700;
    font-size: 1.25rem;
  }
  .info-header .icon {
    margin-left: 3px;
    font-size: 0.9rem;
    line-height: 32px;
  }

  padding: 0 2rem;
  &.rate__item-container__desc {
    padding-right: 1rem;

  }

  &.rate__item-container__value {
    padding-left: 1rem;
    flex-shrink: 0;
    .MuiTypography-root {
      font-variant-numeric: tabular-nums;
    }
  }

}

.rate__item {
  min-width: 33.33%;
  &.rate__item-value,
  &.rate__item-units-start {
    display: flex;
  }
  &.rate__item-diff {
    // color: $success-color;
    &.loss {
      // color: $error-color;
    }
  }
  &.rate__item-value {
    font-weight: 700;
    font-variant-numeric: tabular-nums; // so things line up
  }
}
.rate__item-units-start {
  text-align: left;
}
.rate__item-value {
  text-align: right;
}
.rate__item-diff {
  text-align: center;
}
.value-spacing {
  padding-left: 0.5rem;
}

.rate-details .MuiDialog-paperWidthSm {
  max-width: 650px !important;
}

.rates__section:not(:last-child) {
  margin-bottom: 3rem;
}

.rate__price-class {
  line-height: 2rem !important;
}

.efs__button {
  a {
    color: inherit !important;
  }
}
@media (max-width: $medium) {
  .MuiDialog-paperWidthSm {
    .rates__card {
      margin-top: 5rem;
    }
    .rate__item {
      width: 100%;
    }
    .rates {
      margin: 0 -1.4rem;
    }
    .rate__item-container {
      padding: 0 1.4rem;
      flex-wrap: wrap;
    }
    .rate__item-units-start,
    .rate__item-value {
      text-align: left;
    }
    .rate__item-diff {
      text-align: left;
    }
    .rates__container {
      padding-bottom: 0.875rem;
      padding-top: 0.875rem;
      // background: $lighter-grey;
      &.header {
        background: none;
        margin-top: 0;
      }
    }
    .rate__detail-head {
      display: none;
    }
  }
}

.btn__efs a {
  color: inherit;
}
