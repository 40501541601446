//how to use Reset List:  @include reset-list;
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

//how to use Horizontal List:  @include horizontal-list;
@mixin horizontal-list {
  @include reset-list;
  li {
    display: inline-block;
    margin: {
      left: -2px;
    }
  }
}

// How to use Transform: @include transform(rotate(30deg));
// @mixin transform($property) {
//   -webkit-transform: $property;
//   -ms-transform: $property;
//   transform: $property;
// }

// How to use Headline: @include headline($text-color, $base-font);
// @mixin headline($color, $size) {
//   // color: $color;
//   font-size: $size;
// }

// How to use Box-Shadow : @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
// @mixin box-shadow($shadow...){
//   -webkit-box-shadow: $shadow;
//   -moz-box-shadow: $shadow;
//   box-shadow: $shadow;
// }


// How to user border-radius: @include rounded(0.5rem);
// @mixin rounded($radius) {
//   -webkit-border-radius: $radius;
//   -moz-border-radius: $radius;
//   border-radius: $radius;
// }

// @mixin H0-Medium {
//   // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 26px;
//   line-height: 36px;
//   @media (min-width: $small) {
//     font-size: 3rem;
//     line-height: 4.42rem;
//   }
//   @media (min-width: $medium) {
//     font-size: 4rem;
//     line-height: 5.42rem;
//   }
// }

// @mixin H2-Medium {
//   // // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 24px;
//   line-height: 32px;
//   @media (min-width: $small) {
//     font-size: 36px;
//     line-height: 54px;
//   }
// }
// @mixin H3-Medium {
//   // // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 30px;
//   // color: $dark-black;
//   @media (min-width: $medium) {
//     font-size: 24px;
//     line-height: 36px;
//   }
// }
// @mixin H4-Medium {
//   // // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 27px;
//   // color: $dark-black;
// }
// @mixin H5-Regular {
//   // // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 24px;
//   // color: $dark-black;
// }
// @mixin H5-Medium {
//   @include H5-Regular;
//   font-weight: 500;
// }
// @mixin H6-Medium {
//   // // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 12px;
//   line-height: 18px;
//   letter-spacing: 0.8px;
//   // color: $DarkGrey;
// }
// @mixin P1-Regular {
//   // // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 18px;
//   line-height: 28px;
//   // color: $medium-black;
// }
// @mixin P2-Regular {
//   // // font-family: $font-family-2;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 22px;
//   // color: $dark-black;
// }
// @mixin P3-Regular {
//   // // font-family: $font-family-2;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 21px;
//   // color: $dark-black;
// }
// @mixin P4-Regular {
//   // // font-family: $font-family-2;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 18px;
//   // color: $grey;
// }
// @mixin P5-Regular {
//   // // font-family: $font-family-2;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 13px;
//   line-height: 17px;
//   // color: $grey;
// }
// @mixin Button-Text {
//   // // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 20px;
//   display: flex;
//   align-items: center;
//   text-align: center;
// }

// @mixin tableHeader {
//   // // font-family: $font-family-1;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   // color: $grey;
// }
// @mixin tableCaption {
//   // // font-family: $font-family-2;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 22px;
//   /* identical to box height, or 137% */

//   letter-spacing: 0.8px;
//   text-transform: uppercase;
// }

// @mixin cardDesign {
//   // background: $white;
//   box-shadow: 3px 6px 21px rgba(205, 205, 212, 0.296848);
//   // @include rounded(20px)
// }
