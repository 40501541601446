
.card .bank-account.MuiCard-root.payment-card .payment__details{
  margin-bottom: 8px;
}
.card .MuiCard-root.payment-card {
  display: flex;
  flex-direction: column;
  // background: $white;
  // border: 1px solid $grey;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: none;
  width: 100%;
  max-width: 350px;
  height: 210px;
  padding: 25px;
  position: relative;
  margin-bottom: 20px;
  .detail-link {
    position: absolute;
    right: 26px;
    top: 30px;
    text-decoration: underline;
  }
  .card-images {
    display: flex;
    margin-top: auto;
    justify-content: flex-end;
    align-items: center;
    max-height: 35px;
    img {
      margin: 5px;
      max-height: 35px;
      max-width: 120px;
    }
    .icon {
      font-size: 24px;
      margin-right: 10px;
      // color: $grey;
    }
  }
}
.credit-card-loader{
  text-align: center;
  margin-top: 30px;
}