@import '../../../assets/css/mixin';
@import '../../../assets/css/variables';

.custom-checkbox {
  &.MuiCheckbox-root {
    // color: $grey;
    &.custom {
      padding-top: 0;
      padding-bottom: 0;
    }
    // &.MuiCheckbox-colorPrimary {
    //   &.Mui-checked {
    //     color: $primary-color;
    //   }
    // }
  }
}

.custom-checkbox-row {
  align-items: flex-start;
  margin-bottom: 12px;
  .custom-checkbox.MuiCheckbox-root.custom {
    padding: 0;
    margin-right: 10px;
  }
  .MuiTypography-root {
    width: calc(100% - 40px);
    &.has-error {
      color: #fc5a5a;
      // color: $error-color !important;
    }
  }
}
