@import "../../../assets/css/variables";

div.logo__container{
  @media (max-width: $small) {
    padding-left: 0;
    padding-right: 0;
  }
}
// .brand-logo {
//   a {
//     font-style: normal;
//     font-weight: bold;
//     // color: $text-color;
//     // font-family: $font-family-1;
//     text-decoration: none;
//     font-size: 29px;
//     line-height: 43px;
//     letter-spacing: -0.440506px;
//     // background-image: url(../../../assets/images/logo.png);
//     display: block;
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: left center;
//     height:60px;
//     width:104px;
//     @media (max-width: $large) {
//       // color: $white;
//       // background-image: url(../../../assets/css/themes/img/logo-mob.png);
//       // width: 26px;
//     }
//     @media (max-width: $small) {
//       margin-left: 0;
//     }
//   }
// }
.header .brand-logo a{
  @media (max-width: $large) {
    margin-left: -14px;
  }
  @media (max-width: $small) {
    margin-left: 8px;
  }
}