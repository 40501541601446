@import "../../../assets/css/variables";
@import "../../../assets/css/mixin";

.card{
  .MuiCard-root {
    // @include cardDesign;
    .card-image {
      height: 180px;
    }
    .MuiCardContent-root {
      padding-top: 50px;
      padding-bottom: 40px;
      min-height: 255px;
    }
  }
}