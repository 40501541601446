@import '../../../assets/css/mixin';
@import '../../../assets/css/variables';
// @import '../../../app/themes/Seg/css/themes/theme.scss';

.checkbox-group {
  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 15px;
    position: relative;
    margin-bottom: 5px;
    .MuiButtonBase-root {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 0;
      top: 0;
      left: 0;
      justify-content: flex-start;
      border-radius: 10px;
    }
    .MuiCheckbox-root {
      & ~ .MuiFormControlLabel-label {
  //       background: $1;
  //       border-radius: 10px;
  //       padding: 10px 20px 10px 40px;
  //       border: 1px solid $medium-grey;
  //       font-style: normal;
  // font-weight: normal;
  // font-size: 16px;
  // line-height: 22px;
  //       font-weight: 600;
  //       color: $dark-grey;
  //       height: 48px;
  //       line-height: 28px;
      }
      &.Mui-checked ~ .MuiFormControlLabel-label {
        // background: rgba(0, 98, 255, 0.1);
        // border-color: $primary-color;
        // color: $primary-color;
      }
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      // color: $primary-color;
    }
  }
  &.icon-hidden {
    &.MuiFormControlLabel-root .MuiButtonBase-root {
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          display: none;
        }
      }
    }

    .MuiTypography-root {
      padding-left: 20px !important;
    }
  }
}
