@import '../../../assets/css/mixin';
@import '../../../assets/css/variables';

.accordian-panel {
  &.MuiPaper-elevation1 {
    box-shadow: none;
    &.MuiAccordion-root {
      background: transparent;
      .MuiAccordionSummary-root {
        padding: 0;
        // color: $primary-color;
        justify-content: flex-start;
        &.MuiButtonBase-root {
          .MuiAccordionSummary-content {
            flex: 0 0 auto;
            &.Mui-expanded {
              margin: 12px 0;
            }
          }
          .MuiIconButton-root {
            // color: $primary-color;
          }
        }
        &.Mui-expanded {
          min-height: 48px;
        }
      }
      &.Mui-expanded {
        margin: 0;
      }
      &:before {
        display: none;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
}
